@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.delay-75 {
    animation-delay: 75ms;
}

.animate-scroll-home {
    animation: scroll-home 20s linear infinite;
}

.fade-in-section {
    transform: translateY(20px);
}

.fade-in-section.animate-in {
    transform: translateY(0);
    opacity: 1;
}

@keyframes scroll-home {
    0% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(calc(0px - 50% - 21px));
    }
}
